<style>
	.main .el-tabs__header {
		display: none;
	}
	.main2 .el-tabs__header {
		display: block;
	}
	.video .el-card__header {
		border-color: #1890ff;
	}
	.factory_bg {
		/* background: url("../../assets/factory/9.png") no-repeat left top; */
		/* background-size: 1200px auto; */
		padding: 0px;
		width: 100%;
	}
	.main_factory input {
		background: #082a54;
		border: 0px;
		width: 220px;
		color: #fff;
	}
	.main_factory input::placeholder {
		color: #1890ff;
	}

	.main_factory .el-form-item__content i {
		color: #1890ff;
	}

	.scard6 {
		background: url(../../assets/border6.png) no-repeat left top;
		background-size: auto 100%;
		background: transparent;
		border: 0;
		padding: 0;
	}
	.scard6 .el-table th,
	.scard6 .el-table tr,
	.scard6 td {
		background: transparent;
		border: 0;
		color: #fff;
	}
	.scard6 .el-table--enable-row-hover .el-table__body tr:hover > td {
		background-color: transparent !important;
	}
	.scard6 .el-table::before {
		background: transparent;
	}
	.scard6 input {
		background: #082a54;
		border: 0px;
		width: 220px;
		color: #fff;
	}
	.scard6 input::placeholder {
		color: #1890ff;
	}

	.scard6 .el-form-item__content i {
		color: #1890ff;
	}
	.scard6 .el-form-item__label {
		color: #fff;
	}
</style>
<template>
	<div style="background:#081c35;width:100%;min-height:100%;">

		<el-form class="scard6" style="position: absolute;z-index: 999;padding: 15px;" ref="searchForm" :model="searchForm" label-width="auto" size="mini" inline>
			<span style="color: rgb(255, 255, 255);margin-right:40px"><span style="border-bottom: 2px solid rgb(0, 228, 252);">停电感知台区定位系统</span>
			</span>
			<el-form-item label="选择线路">
				<el-select v-model="searchForm.gpoint_id" filterable placeholder="请选择线路" @change="show_map">
					<el-option label="待王变待47板10kVa右线" value="1" />
					<el-option label="建业变81Ⅰ神州西线" value="2" />
					<el-option label="许衡变衡57板中南左线" value="3" />
				</el-select>
			</el-form-item>
			<el-form-item label="选择台区">
				<el-select width="600px" v-model="searchForm.keywords" filterable clearable placeholder="请选择台区" @change="search_tableData">
					<el-option v-for="item in tableData" :key="item.id" :label="item.area+' '+item.supply+' '+item.gpoint_name" :value="item.id" />
				</el-select>
			</el-form-item>
			<el-form-item label="维修人员">
				<el-select v-model="searchForm.uid" filterable clearable placeholder="请选择维修人员">
					<el-option label="维修员aa" value="1" />
					<el-option label="维修员bb" value="2" />
					<el-option label="维修员cc" value="3" />
				</el-select>
			</el-form-item>
			<el-button type="primary" size="mini" style="margin-left:20px" @click="push_ok">立即派单</el-button>
		</el-form>

		<div :style="'height:'+height+'px'">
			<mmap ref="map" :center="center" :markers="markers" :zoom="zoom" :polylinePath="markers" />
		</div>
	</div>
</template>
<script>
	import request from "../../plugins/axios";
	import mmap from "../../components/mmap2";
	export default {
		data() {
			return {
				height: document.documentElement.clientHeight - 34,
				cur_gline_id: 0,
				searchForm: {
					gpoint_id: "1",
					keyword: "",
				},
				center: { lng: 113.248557, lat: 35.213354 },
				zoom: 12,
				markers: [],
				tableData: [],
				expireTimeOption: {
					disabledDate(date) {
						//不能选未来的日期
						return date.getTime() >= Date.now();
					},
				},
			};
		},
		components: {
			mmap,
		},
		mounted() {
			setTimeout(() => {
				this.show_map(1);
			}, 1000);
		},
		methods: {
			search_tableData(id) {
				request({
					url: "/admin/Gis/gpoint_info/" + id,
					data: {},
				}).then((ret) => {
					this.$refs["map"].lookDetail(ret.data.gpoint);
					// this.center = {
					// 	lng: ret.data.gpoint.lng,
					// 	lat: ret.data.gpoint.lat,
					// };
				});
			},
			push_ok() {
				this.$notify({
					title: "派单成功",
					message: "已将经纬度推送给当前值班的维修人员手机上",
					type: "success",
				});
			},
			show_map(gline_id) {
				// this.zoom = 12;
				request({
					url: "/admin/Gis/gpoint_list",
					data: {
						gline_id,
					},
				}).then((ret) => {
					this.cur_gline_id = gline_id;
					this.markers = [];
					this.tableData = ret.data.gpoint_list;

					// this.center = {
					// 	lng:
					// 		ret.data.gpoint_list[Math.round(ret.data.gpoint_list.length / 2)]
					// 			.lng,
					// 	lat:
					// 		ret.data.gpoint_list[Math.round(ret.data.gpoint_list.length / 2)]
					// 			.lat,
					// };
					for (let i = 0; i < ret.data.gpoint_list.length; i++) {
						setTimeout(() => {
							this.markers.push(ret.data.gpoint_list[i]);
						}, 50 * i);
					}
				});
			},
		},
	};
</script>